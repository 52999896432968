// extracted by mini-css-extract-plugin
export var sbShowMain = "imageText-module--sb-show-main--2OtYJ";
export var wrapper = "imageText-module--wrapper--1iUGH";
export var right = "imageText-module--right--1CQ8k";
export var imageWrapper = "imageText-module--imageWrapper--1ewgA";
export var textWrapper = "imageText-module--textWrapper--bZg-2";
export var mainImage = "imageText-module--mainImage--2f4nX";
export var image = "imageText-module--image--2tA0d";
export var showIcons = "imageText-module--showIcons--SfaDU";
export var iconsContainer = "imageText-module--iconsContainer--2CHY1";
export var storybook = "imageText-module--storybook--3YRG2";