import React from "react"
import classnames from "classnames"
import PropTypes from "prop-types"

import RichText from "../richText/richText"
import Image from "../image/image"

import * as styles from "./imageText.module.scss"
import SlideIn from "../slideIn/slideIn"

const ImageText = props => {
  const { images, text, imgPosition, showIcons, icon1, icon2, icon3, slideIn, title, background } = props
  const isGradientBackground = background === "gradient"

  const SlideInImages = () =>
    images.map((image, index) =>
      index === 0 ? (
        // first image, dictates height => is relative
        <SlideIn order={index} direction={imgPosition} key={index}>
          <Image alt={image?.alt} image={image?.asset} className={styles.mainImage} />
        </SlideIn>
      ) : (
        // any other image, needs absolute to keep layers
        <SlideIn order={index} direction={imgPosition} absolute key={index}>
          <Image alt={image?.alt} image={image?.asset} className={styles.image} />
        </SlideIn>
      )
    )

  const StaticImages = () =>
    images.map((image, index) =>
      index === 0 ? (
        // first image, dictates height => is relative
        <Image key={index} alt={image?.alt} image={image?.asset} className={styles.mainImage} />
      ) : (
        // any other image, needs absolute to keep layers
        <Image key={index} alt={image?.alt} image={image?.asset} className={styles.image} />
      )
    )

  const Images = () => (slideIn ? <SlideInImages /> : <StaticImages />)

  return (
    <div>
      {title && <h2 className="text-align-center mb-s">{title}</h2>}
      <div className={classnames(styles.wrapper, styles[imgPosition])}>
        <div className={classnames(styles.imageWrapper)}>
          <Images />
        </div>
        <div
          className={classnames(
            styles.textWrapper,
            showIcons && styles.showIcons,
            !isGradientBackground && "darkgrey"
          )}>
          {text ? <RichText blocks={text} /> : null}
          {showIcons && (
            <div className={styles.iconsContainer}>
              <div className="icon xs">{icon1 && <img src={icon1.asset.url} alt="" />}</div>
              <div className="icon s">{icon2 && <img src={icon2.asset.url} alt="" />}</div>
              <div className="icon xs">{icon3 && <img src={icon3.asset.url} alt="" />}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

ImageText.propTypes = {
  images: PropTypes.array.isRequired,
  icon1: PropTypes.object,
  icon2: PropTypes.object,
  icon3: PropTypes.object,
  text: PropTypes.array,
  showIcons: PropTypes.bool,
  slideIn: PropTypes.bool,
  imgPosition: PropTypes.string,
  title: PropTypes.string,
}

ImageText.defaultProps = {
  showIcons: false,
  imgPosition: "right",
  images: [],
  slideIn: false,
  title: "",
}

export default ImageText
