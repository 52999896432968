import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import { BACKGROUNDS } from "../consts"
import RichText from "../richText/richText"

import * as styles from "./listModule.module.scss"

const ListModule = props => {
  const { lists, icons, title, richText } = props

  const hasIcons = !!icons.length

  const renderIcons = () => {
    if (!hasIcons) return null
    const [icon1, icon2, icon3] = icons
    return (
      <div className={styles.iconsContainer}>
        <div className="icon m">
          <img src={icon1.asset.url} alt="" />
        </div>
        <div className="icon xxl">
          <img src={icon2.asset.url} alt="" />
        </div>
        <div className="icon m">
          <img src={icon3.asset.url} alt="" />
        </div>
      </div>
    )
  }

  return (
    <div className={classnames(styles.listModule, hasIcons && styles.withIcons)}>
      <div className={classnames(styles.listContainer, "container container_rounded")}>
        {renderIcons()}
        <h3 className="text-align-center">{title}</h3>
        <div className={styles.lists}>
          {lists.map(list => (
            <RichText key={list._key} blocks={list.children} />
          ))}
        </div>
        {richText?.length && (
          <div className={styles.body}>
            <RichText blocks={richText} />
          </div>
        )}
      </div>
    </div>
  )
}

ListModule.propTypes = {
  title: PropTypes.string,
  lists: PropTypes.arrayOf(PropTypes.any),
  background: PropTypes.oneOf(BACKGROUNDS),
  icons: PropTypes.arrayOf(PropTypes.any),
}
ListModule.defaultProps = {
  title: "",
  lists: [],
  icons: [],
  background: "",
}

export default ListModule
