// extracted by mini-css-extract-plugin
export var sbShowMain = "faq-module--sb-show-main--3fMlc";
export var faq = "faq-module--faq--K6ncT";
export var buttonContainer = "faq-module--buttonContainer--2ZzwJ";
export var mainText = "faq-module--mainText--3t0k9";
export var body = "faq-module--body--12QmS";
export var left = "faq-module--left--2hpDH";
export var steps = "faq-module--steps--2GUkB";
export var step = "faq-module--step--1pEhC";
export var header = "faq-module--header--3yp52";
export var reverse = "faq-module--reverse--2rT1u";
export var text = "faq-module--text--1UsXw";
export var noImage = "faq-module--noImage--3R9Uq";
export var imageContainer = "faq-module--imageContainer--2K17m";