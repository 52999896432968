// extracted by mini-css-extract-plugin
export var sbShowMain = "pricingCourses-module--sb-show-main--34UfF";
export var cardContainer = "pricingCourses-module--cardContainer--60azs";
export var cardWrapper = "pricingCourses-module--cardWrapper--qdtiE";
export var title = "pricingCourses-module--title--EMpFB";
export var edu = "pricingCourses-module--edu--2lHXd";
export var work = "pricingCourses-module--work--xF68t";
export var enterprise = "pricingCourses-module--enterprise--3VkSZ";
export var content = "pricingCourses-module--content--3ESKR";
export var price = "pricingCourses-module--price--3fILE";
export var buttonContainer = "pricingCourses-module--buttonContainer--3mb3B";
export var allCoursesButton = "pricingCourses-module--allCoursesButton--2jtI2";