import React, { useCallback, useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import truncate from "lodash/truncate"
import upperFirst from "lodash/upperFirst"

import Image from "../image/image"
import FindOutMore from "../findOutMore/findOutMore"
import FormattedLink from "../formattedLink/formattedLink"
import { getPathAndLabel } from "./helpers/getPathAndLabel"
import Label from "../label/label"
import { useTranslation } from "../../helpers/useTranslation"

import * as styles from "./moreReading.module.scss"

const isMobile =
  typeof window === "undefined"
    ? false
    : /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const SmallReadingCard = props => {
  const { description, logo, title, withButton, page, label } = props
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const cardRef = useRef()
  const { url, label: entityLabel } = getPathAndLabel(page)
  const generatedLabel = t(entityLabel)

  const watchOutsideClick = useCallback(e => {
    if (!cardRef.current.contains(e.target)) setOpen(false)
  }, [])

  /**
   * Only for mobile
   * If the card is open, listens outside click to close the card
   */
  useEffect(() => {
    if (!open || !cardRef.current || !isMobile) return
    document.addEventListener("click", watchOutsideClick)
    return () => document.removeEventListener("click", watchOutsideClick)
  }, [open, watchOutsideClick])

  const handleMobileClick = useCallback(() => {
    if (!open) setOpen(true)
  }, [open])

  const renderBody = () => {
    const cutDescription = truncate(description, { length: 60, omission: " ...", separator: " " })
    const titleComponent = <div className={classNames(description ? "body3 bold" : "h5")}>{title}</div>
    const descComponent = !!description && (
      <div className={classNames("body6", styles.description)}>{cutDescription}</div>
    )

    return (
      <div className={classNames(styles.body, description && styles.withDesc, withButton, styles.withButton)}>
        {titleComponent}
        {descComponent}
        {withButton && <FindOutMore className={styles.buttonLink} label={upperFirst(t("read more"))} />}
      </div>
    )
  }

  const clickableProps = isMobile
    ? {
        onClick: handleMobileClick,
        onKeyDown: handleMobileClick,
        role: "link",
      }
    : {}

  return (
    <div ref={cardRef} className={styles.smallReadingCard} {...clickableProps}>
      <div className={classNames(styles.imageContainer)}>
        <Image image={logo?.asset} />
        <Label className={styles.label} title={generatedLabel} {...label} />
      </div>
      {renderBody()}
      {(!isMobile || open) && <FormattedLink url={url} className={styles.linkContainer} />}
    </div>
  )
}

SmallReadingCard.propTypes = {
  description: PropTypes.string,
  logo: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
  withButton: PropTypes.bool,
  label: PropTypes.shape({ title: PropTypes.string, color: PropTypes.string }),
}

SmallReadingCard.defaultProps = {
  description: "",
  withButton: false,
  label: {},
}

export default SmallReadingCard
