// Modules
import React from 'react';
import cx from 'classnames';

// Components
import Buttons from '../buttons/buttons';
import IconElement from './iconElement';

// styles
import * as s from './iconModule.module.scss';

function IconModuleVersion2(props) {
  const {
    background,
    buttons,
    icons,
    sizeStyle,
    subtitle,
    title,
  } = props;

  const containerStyles = cx(
    s['icons'],
    background === "gradient" && s['gradient'],
  )
  const wrapperStyles = cx(s['wrapper'], background === "gradient" && s['gradient'])

  return (
    <div className={wrapperStyles}>
      <h2 className={cx('text-align-left mb-xs', s['title'])}>{title}</h2>
      <p className={cx('text-align-left', s['subtitle'])}>{subtitle}</p>
      <div className={containerStyles}>
        {icons.map(icon => (
          <IconElement
            key={icon._key}
            size={sizeStyle}
            {...icon}
          />
        ))}
      </div>
      {buttons && <Buttons buttons={buttons} />}
    </div>
  );
}

export default IconModuleVersion2;
