import React from "react"

import Section from "../section/section"
import ImageText from "../imageText/imageText"
import VideoText from "../videoText/videoText"
import ListModule from "../listModule/listModule"
import IconModule from "../iconModule/iconModule"
import IconModuleVersion2 from "../iconModuleVersion2"
import CtaEduWork from "../ctaEduWork/ctaEduWork"
import Faq from "../faq/faq"
import FaqNoImage from "../faq/faqNoImage"
import Cta from "../cta/cta"
import ButtonsSection from "../buttonsSection/buttonsSection"
import FormText from "../formText/formText"
import RichText from "../richText/richText"
import Testimonial from "../testimonial/testimonial"
import Awards from "../awards/awards"
import MoreReading from "../moreReading/moreReading"
import PartnersCarousel from "../partnersCarousel/partnersCarousel"
import PricingCourses from "../pricingCourses/pricingCourses"
import TestimonialListing from "../testimonial/testimonialListing"
import ProfessionalServices from "../professionalServices"

const getComponents = content => {
  switch (content?._type) {
    case "listModule":
      return <ListModule {...content} />
    case "imageText":
      return <ImageText {...content} />
    case "formText":
      return <FormText {...content} />
    case "videoText":
      return <VideoText {...content} />
    case "pbRichText":
      return <RichText {...content} narrower={content.narrower} blocks={content.richText} />
    case "iconModule":
      return <IconModule {...content} />
    case "iconModuleVariant2":
      return <IconModuleVersion2 {...content} />
    case "ctaEduWork":
      return <CtaEduWork {...content} />
    case "faq":
      return <Faq {...content} />
    case "faqNoImage":
      return <FaqNoImage {...content} />
    case "cta":
      return <Cta {...content} />
    case "buttonsSection":
      return <ButtonsSection {...content} />
    case "testimonialPB":
      return <Testimonial {...content?.testimonial} />
    case "awards":
      return <Awards {...content} />
    case "moreReading":
    case "moreReadingCS":
      return <MoreReading {...content} />
    case "partnersCarousel":
      return <PartnersCarousel {...content} />
    case "pricingCourses":
      return <PricingCourses {...content} />
    case "testimonialListingContent":
      return <TestimonialListing {...content} />
    case "professionalServicesPB":
      return <ProfessionalServices {...content} />
    default:
      return null
  }
}

const getBackground = content => {
  if (content?._type === "cta") return "gradient"
  if (content?._type === "testimonialListingContent") return "grey-gradient"
  return content.background
}

const PageBuilder = ({ pageBuilder, theme = "default" }) => {
  return (
    <>
      {pageBuilder.map((content, idx) => {
        const bgColor = getBackground(content)

        return (
          <Section key={content._key || content._id || content.id || idx} background={bgColor}>
            {getComponents({ ...content, bgColor, theme })}
          </Section>
        )
      })}
    </>
  )
}

export default PageBuilder
