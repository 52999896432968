import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import Image from "../image/image"
import RichText from "../richText/richText"
import FormattedLink from "../formattedLink/formattedLink"

import * as styles from "./pricingCourses.module.scss"

const PcCard = props => {
  const { body, label, url, icon, price, theme, title } = props

  return (
    <div className={styles.cardWrapper}>
      <h4 className={cx("text-align-center", styles.title, styles[theme])}>{title}</h4>
      <div className={styles.content}>
        <Image className="text-align-center mb-s" image={icon.asset} />
        <RichText className="mb-m" blocks={body} />
        {price && <h4 className={cx(styles.price, "text-align-center")}>{price}</h4>}
        {label && (
          <FormattedLink url={url} label={label} className={cx("btn", styles.buttonContainer, styles[theme])} />
        )}
      </div>
    </div>
  )
}

PcCard.propTypes = {
  body: PropTypes.arrayOf(PropTypes.any),
  label: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.object,
  price: PropTypes.string,
  theme: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

PcCard.defaultProps = {
  body: [],
  label: "",
  url: "",
  icon: {},
  price: "",
}

export default PcCard
