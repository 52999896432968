import React from "react"
import cx from "classnames"

import IconElement from "./iconElement"
import Buttons from "../buttons/buttons"

import * as styles from "./iconModule.module.scss"

function IconModule(props) {
  const { icons, rowCount, title, subtitle, background, buttons } = props
  const has4 = icons?.length === 4
  const has5 = icons?.length === 5
  const has6 = icons?.length === 6
  const has7 = icons?.length === 7
  const has8 = icons?.length === 8
  const has9 = icons?.length === 9
  const singleRow = rowCount === 1
  const twoRows = rowCount === 2
  const threeRows = rowCount === 3
  const fourRows = rowCount === 4
  const sizeStyle = has4 && singleRow ? "xl" : "xxl"
  const containerStyles = cx(
    styles.icons,
    background === "gradient" && styles.gradient,
    styles[`iconSize${sizeStyle.toUpperCase()}`]
  )
  const wrapperStyles = cx(styles.wrapper, background === "gradient" && styles.gradient)

  const firstRow = singleRow
    ? icons
    : (twoRows && has4) || (threeRows && has6) || (threeRows && has5) || (fourRows && has8)
    ? icons.slice(0, 2)
    : (twoRows && has5) || (threeRows && (has6 || has7 || has8 || has9))
    ? icons.slice(0, 3)
    : ""
  const secondRow =
    (twoRows && has4) || (threeRows && has6) || (threeRows && has5) || (fourRows && has8)
      ? icons.slice(2, 4)
      : twoRows && has5
      ? icons.slice(3, 5)
      : (twoRows && has6) || (threeRows && (has7 || has8 || has9))
      ? icons.slice(3, 6)
      : ""
  const thirdRow =
    threeRows && has5
      ? icons.slice(4, 5)
      : threeRows && has6
      ? icons.slice(4, 6)
      : (threeRows && (has7 || has8 || has9))
      ? icons.slice(6, 10)
      : fourRows && has8
      ? icons.slice(4, 6)
      : ""
  const fourthRow =
    fourRows && has8
      ? icons.slice(-2)
      : ""

  const renderRow = data =>
    data ? (
      <div className={styles.row}>
        {data.map(icon => (
          <IconElement key={icon._key} size={sizeStyle} rowCount={rowCount} {...icon} />
        ))}
      </div>
    ) : null

  return (
    <div className={wrapperStyles}>
      <h2 className={cx("text-align-center mb-xs", styles.title)}>{title}</h2>
      <p className={cx("text-align-center", styles.subtitle)}>{subtitle}</p>
      <div className={containerStyles}>
        {renderRow(firstRow)}
        {renderRow(secondRow)}
        {renderRow(thirdRow)}
        {renderRow(fourthRow)}
      </div>
      <Buttons buttons={buttons} />
    </div>
  )
}

export default IconModule
