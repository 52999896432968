// extracted by mini-css-extract-plugin
export var sbShowMain = "videoText-module--sb-show-main--8GClY";
export var wrapper = "videoText-module--wrapper--3dx8F";
export var offset = "videoText-module--offset--mPxKk";
export var right = "videoText-module--right--3WR-E";
export var videoWrapper = "videoText-module--videoWrapper--1G9SR";
export var laptopAndVideoWrapper = "videoText-module--laptopAndVideoWrapper--26ewl";
export var modalButton = "videoText-module--modalButton--1MdlF";
export var video = "videoText-module--video--2v-EY";
export var preview = "videoText-module--preview--1MzU2";
export var textWrapper = "videoText-module--textWrapper--2ajIj";
export var iconsContainer = "videoText-module--iconsContainer--uvYqL";