import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import RichText from "../richText/richText"
import Step from "./step"
import Buttons from "../buttons/buttons"

import * as styles from "./faq.module.scss"

const FaqNoImage = props => {
  const { title, text, steps, buttons } = props
  const [openSteps, setOpenSteps] = useState(() => props.steps?.map((_, idx) => !idx))

  const handleSwitchOpen = useCallback(
    index => {
      const newStatus = !openSteps[index]
      const newOpenSteps = [...openSteps.slice(0, index), newStatus, ...openSteps.slice(index + 1)]

      setOpenSteps(newOpenSteps)
    },
    [openSteps]
  )

  const renderSteps = () => (
    <div className={styles.steps}>
      {steps.map((step, idx) => (
        <Step key={step._key} index={idx} {...step} open={openSteps[idx]} setOpen={() => handleSwitchOpen(idx)} />
      ))}
    </div>
  )

  return (
    <div className={styles.faq}>
      <h2 className="text-align-center mb-s">{title}</h2>
      <div className={classNames("text-align-center mb-m", styles.mainText)}>
        <RichText blocks={text} />
      </div>
      <div className={classNames(styles.body, styles.noImage)}>
        {renderSteps()}
      </div>
      <Buttons className={styles.buttonContainer} buttons={buttons} />
    </div>
  )
}

FaqNoImage.propTypes = {
  title: PropTypes.string,
  text: PropTypes.arrayOf(PropTypes.any),
  steps: PropTypes.arrayOf(PropTypes.any),
  buttons: PropTypes.arrayOf(PropTypes.any),
}

FaqNoImage.defaultProps = {
  title: "",
  text: [],
  steps: [],
  buttons: [],
}

export default FaqNoImage
