// extracted by mini-css-extract-plugin
export var sbShowMain = "iconModule-module--sb-show-main--35ZwH";
export var wrapper = "iconModule-module--wrapper--QoJik";
export var title = "iconModule-module--title--10pkt";
export var subtitle = "iconModule-module--subtitle--2HVxt";
export var gradient = "iconModule-module--gradient--3ZRKP";
export var icons = "iconModule-module--icons--Z_xZ1";
export var row = "iconModule-module--row--1eG97";
export var decoration = "iconModule-module--decoration--35kpm";
export var iconElement = "iconModule-module--iconElement--1hJ32";
export var totaraBackground = "iconModule-module--totaraBackground--WXARW";
export var pureImage = "iconModule-module--pureImage--1kZMO";
export var bigTitle = "iconModule-module--bigTitle--IfA4p";
export var twoRows = "iconModule-module--twoRows--K8Fpd";
export var description = "iconModule-module--description--3Bvzm";
export var storybook = "iconModule-module--storybook--1E0GV";
export var findOutMore = "iconModule-module--findOutMore--3XxTh";