import React from "react"
import PropTypes from "prop-types"

import upperFirst from "lodash/upperFirst"
import { VscQuote } from "react-icons/vsc"

import Image from "../image/image"
import FindOutMore from "../findOutMore/findOutMore"
import Label from "../label/label"
import { useTranslation } from "../../helpers/useTranslation"

import * as styles from "./moreReading.module.scss"

const MediumReadingCard = props => {
  const { excerpt, hoverTitle, hoverDesc, label, link, logo, name, quoteMark, role } =
    props?.tabs?.testimonialListingPreview
  const hasQuoteMark = quoteMark === true
  const { t } = useTranslation()

  const renderBody = () => {
    return (
      <div className={styles.body}>
        <div className={styles.quoteText}>
          <p className="italic">{quoteMark ? `“${excerpt}”` : excerpt}</p>
        </div>
        <div className={styles.imageContainer}>
          <Image image={logo?.asset} />
        </div>
        <div className={styles.authorContainer}>
          <p className="body3 bold uppercase">{name}</p>
          <p className="body6">{role}</p>
        </div>
        <div className={styles.hoverContentContainer}>
          <p className="mb-xxs body3 bold">{hoverTitle}</p>
          <p className="mb-xs body6">{hoverDesc}</p>
          {link && <FindOutMore className={styles.buttonLink} url={link} label={upperFirst(t("read more"))} />}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.mediumReadingCard}>
      {hasQuoteMark && (
        <div className="icon">
          <VscQuote color="black" />
        </div>
      )}
      <Label className={styles.label} {...label} />
      {renderBody()}
    </div>
  )
}

MediumReadingCard.propTypes = {
  excerpt: PropTypes.string,
  hoverTitle: PropTypes.string,
  hoverDesc: PropTypes.string,
  label: PropTypes.shape({ title: PropTypes.string, color: PropTypes.string }).isRequired,
  link: PropTypes.string,
  logo: PropTypes.objectOf(PropTypes.any),
  name: PropTypes.string,
  quoteMark: PropTypes.bool,
  role: PropTypes.string,
}

MediumReadingCard.defaultProps = {
  excerpt: "",
  hoverTitle: "",
  hoverDesc: "",
  label: {},
  link: "",
  name: "",
  quoteMark: false,
  role: "",
}

export default MediumReadingCard
