import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import SmallReadingCard from "./smallReadingCard"
import BigReadingCard from "./bigReadingCard"

import * as styles from "./moreReading.module.scss"

const MoreReading = props => {
  const { cards, className, title, subtitle } = props

  const renderCards = () => {
    const smallCards = cards.filter(({ _type }) => _type === "smallReadingCard" || _type === "smallReadingCardCS")
    const bigCards = cards.filter(({ _type }) => _type === "bigReadingCard" || _type === "bigReadingCardCS")
    if (bigCards.length > 1) {
      return (
        <div className={styles.bigCardContainer}>
          {bigCards.map(card => (
            <BigReadingCard key={card._key} {...card} />
          ))}
        </div>
      )
    }

    const leftCards = smallCards.slice(0, 4)
    const middleCard = bigCards[0]
    const rightCards = smallCards.slice(4, 8)

    return (
      <div className={styles.list}>
        <div className={styles.leftContainer}>
          {leftCards.map(card => (
            <SmallReadingCard key={card._key} {...card} />
          ))}
        </div>
        {middleCard && (
          <div className={styles.middleContainer}>
            <BigReadingCard {...middleCard} />
          </div>
        )}
        <div className={styles.rightContainer}>
          {rightCards.map(card => (
            <SmallReadingCard key={card._key} {...card} />
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className={classNames(styles.moreReading, className)}>
      {title && <h2 className="text-align-center mb-s">{title}</h2>}
      {subtitle && <div className={classNames(styles.subtitle, "body2")}>{subtitle}</div>}
      {renderCards()}
    </div>
  )
}

MoreReading.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.any).isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

MoreReading.defaultProps = {
  className: "",
  title: "",
  subtitle: "",
}

export default MoreReading
