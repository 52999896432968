import React from "react"
import PropTypes from "prop-types"
import FindOutMore from "../findOutMore/findOutMore"
import cx from "classnames"

import * as s from "./iconModule.module.scss";

function IconElement({ bigTitle, description, image, url, label, pureImage, title, totara }) {

  return (
    <div className={s['iconElement']}>
      <div className={cx(s['decoration'], {
        [s['totaraBackground']]: totara,
        [s['pureImage']]: pureImage,
      })}>
        <img src={image.asset.url} alt={title} />
      </div>
      <div className={s['rightSide']}>
        <h4 className={cx('mb-s', bigTitle && s['bigTitle'])}>{title}</h4>
        <div className={s['description']}>{description}</div>
        {label && <FindOutMore className={s['findOutMore']} url={url} label={label} styleType="primary" />}
      </div>
    </div>
  )
}

IconElement.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.string,
  url: PropTypes.string,
}

IconElement.defaultProps = {
  title: "",
  description: "",
  label: null,
  url: "",
}

export default IconElement
