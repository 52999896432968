import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import _ from "lodash"

import * as styles from "./awards.module.scss"

const Awards = props => {
  const { title, award } = props

  const Stars = ({ isPink }) => (
    <div className={styles.stars}>
      {_.times(5, i => (
        <i key={i} className={cx(styles.star, isPink && styles.pinkStar)}></i>
      ))}
    </div>
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.ribbon}>
        <Stars isPink />
        <h2 className="text-align-center">{title}</h2>
        <Stars />
      </div>
      <div className={styles.box}>
        <div className={styles.awards}>
          {award?.map(item => (
            <img key={item._key} src={item.awardImage.asset.url} alt={item.awardTitle} loading="lazy" />
          ))}
        </div>
      </div>
    </div>
  )
}

Awards.propTypes = {
  title: PropTypes.string,
  award: PropTypes.array,
}

Awards.defaultProps = {
  title: "",
  award: [],
}

export default Awards
