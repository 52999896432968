import React from "react"
import cx from "classnames"
import { useInView } from "react-intersection-observer"
import Fade from "react-reveal/Fade"

import RichText from "../richText/richText"
import VideoInline from "../videoInline/videoInline"
import IframeEmbed from "../iframeEmbed/iframeEmbed"
import PlayButton from "../playButton/playButton"
import Modal from "../modal/modal"
import Laptop from "../../../assets/images/laptop.png"

import * as styles from "./videoText.module.scss"

const VideoText = ({ title, text, poster, videoPosition, video, url }) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })
  const renderVideoWithUrl = () => (
    <div className={styles.laptopAndVideoWrapper}>
      <img src={Laptop} alt="tablet" />
      <Modal
        isAuto
        canScroll
        className={styles.modalButton}
        modalClassName={styles.video}
        modalContent={<IframeEmbed url={url} title="OpenLMS introduction" />}>
        <PlayButton />
      </Modal>
      <div className={styles.preview}>
        <img src={poster?.asset?.url} alt="preview" />
      </div>
    </div>
  )
  return (
    <>
      {title && <h2 className="text-align-center mb-m">{title}</h2>}
      <div className={cx(styles.wrapper, styles[videoPosition])}>
        <Fade {...(videoPosition === "left" || undefined ? { left: true } : { right: true })}>
          {/* VIDEO */}
          <div className={styles.videoWrapper} ref={ref}>
            {video && inView && (
              <div>
                <VideoInline {...video.asset} poster={poster?.asset} visible={inView} />
              </div>
            )}
            {!video && url && renderVideoWithUrl()}
          </div>
        </Fade>

        {/* TEXT */}
        {text ? (
          <div className={styles.textWrapper}>
            <RichText blocks={text} />
          </div>
        ) : null}
      </div>
    </>
  )
}

export default VideoText
