// extracted by mini-css-extract-plugin
export var sbShowMain = "iconModule-module--sb-show-main--1ZEk0";
export var wrapper = "iconModule-module--wrapper--c871a";
export var title = "iconModule-module--title--3NQ-r";
export var subtitle = "iconModule-module--subtitle--ZBKSM";
export var gradient = "iconModule-module--gradient--1YRNy";
export var icons = "iconModule-module--icons--38Q-d";
export var decoration = "iconModule-module--decoration--2hOSd";
export var iconElement = "iconModule-module--iconElement--qE6gf";
export var totaraBackground = "iconModule-module--totaraBackground--3C4GD";
export var pureImage = "iconModule-module--pureImage--3rMIm";
export var bigTitle = "iconModule-module--bigTitle--1_Wa3";
export var rightSide = "iconModule-module--rightSide--31JTP";
export var twoRows = "iconModule-module--twoRows--33357";
export var description = "iconModule-module--description--3cdU9";