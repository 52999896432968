import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import * as styles from "./label.module.scss"

const Label = props => {
  const { title, color, className, children } = props
  let colorClassName = color

  if (!title) return null

  if (!color) {
    const label = title.toLowerCase()
    if (label.includes("edu")) colorClassName = "eduGradient"
    else if (label.includes("case stud") || label.includes("work")) colorClassName = "workGradient"
    else if (label.includes("totara")) colorClassName = "totaraGradient"
    else colorClassName = "mainGradient"
  }

  return <div className={classNames(styles.label, styles[colorClassName], className)}>{title || children}</div>
}

const colors = ["", "mainPrimary", "mainSecondary", "mainGradient", "eduGradient", "workGradient", "totaraGradient"]

Label.propTypes = {
  title: PropTypes.string,
  color: PropTypes.oneOf(colors),
  className: PropTypes.string,
}

Label.defaultProps = {
  className: "",
  title: "",
  color: "",
}

export default Label
