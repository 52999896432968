import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { useInView } from "react-intersection-observer"

import * as styles from "./slideIn.module.scss"

/*
 *  This component was used for image text component and wasn't tested with other components
 *
 *   <SlideIn order=0 direction="left">
 *     <MyComponent />
 *   </SlideIn>
 *
 */

const DELAY = {
  0: "0s",
  1: ".4s",
  2: ".8s",
}

const SlideIn = ({ children, order, direction, absolute }) => {
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  })

  return (
    //   wrapper, positioned absolutely when elements are displayed in layers
    <div ref={ref} className={cx(styles.wrapper, absolute ? styles.absolute : "")} style={{ zIndex: order }}>
      {/* part that slides in */}
      <div
        className={cx(styles.inner, styles[direction], inView ? styles.slideIn : "")}
        style={{ animationDelay: DELAY[order], zIndex: order }}>
        {children}
      </div>
    </div>
  )
}

SlideIn.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  order: PropTypes.number.isRequired,
  direction: PropTypes.string.isRequired,
  absolute: PropTypes.bool,
}

SlideIn.defaultProps = {
  children: [],
  order: 0,
  direction: "left",
  absolute: false,
}

export default SlideIn
