import React, { useCallback, useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Fade from "react-reveal/Fade"
import { IoIosArrowDropdown } from "react-icons/io"

import * as styles from "./faq.module.scss"

function Step(props) {
  const stepRef = useRef()
  const { title, text, className, open, setOpen } = props

  const renderBody = () => {
    return (
      <Fade left collapse when={open} duration={700}>
        <div className={styles.text}>
          <div>{text}</div>
        </div>
      </Fade>
    )
  }

  const handleSwitch = useCallback(() => {
    const newOpen = !open

    if (stepRef.current && newOpen) stepRef.current.scrollIntoView({ behavior: "smooth", block: "center" })

    setTimeout(() => setOpen(newOpen), 200)
  }, [open, setOpen])

  return (
    <div
      ref={stepRef}
      className={classNames(styles.step, className)}
      onClick={handleSwitch}
      onKeyDown={handleSwitch}
      role="button"
      tabIndex={0}>
      <div className={styles.header}>
        <h4 className="h5">{title}</h4>
        <IoIosArrowDropdown size={30} className={classNames(open && styles.reverse)} />
      </div>
      {renderBody()}
    </div>
  )
}

Step.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  defaultOpen: PropTypes.bool,
}

Step.defaultProps = {
  title: "",
  text: "",
  className: "",
  defaultOpen: false,
}

export default Step
