import React from "react"
import PropTypes from "prop-types"
import FindOutMore from "../findOutMore/findOutMore"
import cx from "classnames"

import * as styles from "./iconModule.module.scss"
function IconElement({ title, bigTitle, description, image, url, label, size, rowCount, totara, pureImage }) {
  const twoRows = +rowCount === 2

  return (
    <div className={cx(styles.iconElement, twoRows && styles.twoRows)}>
      <div className={cx(`icon ${size} `, styles.decoration, {
        [styles.totaraBackground]: totara,
        [styles.pureImage]: pureImage,
      })}>
        <img src={image.asset.url} alt={title} />
      </div>
      <h4 className={cx("text-align-center mb-s", bigTitle && styles.bigTitle)}>{title}</h4>
      <div className={cx("text-align-center", styles.description)}>{description}</div>
      {label && <FindOutMore className={styles.findOutMore} url={url} label={label} styleType="primary" />}
    </div>
  )
}

IconElement.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.string,
  url: PropTypes.string,
  size: PropTypes.oneOf(["xxl", "xl"]),
}

IconElement.defaultProps = {
  title: "",
  description: "",
  size: "xxl",
  label: null,
  url: "",
}

export default IconElement
