import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Fade from "react-reveal/Fade"
import useThrottleFn from "ahooks/lib/useThrottleFn"
import findLastIndex from "lodash/findLastIndex"

import RichText from "../richText/richText"
import Step from "./step"
import Image from "../image/image"
import Buttons from "../buttons/buttons"

import * as styles from "./faq.module.scss"

const FAQ = props => {
  const [selectedImage, setSelected] = useState(() => props.steps?.[0]?.image)
  const { title, text, steps, buttons, imgPosition } = props
  const [openSteps, setOpenSteps] = useState(() => props.steps?.map((_, idx) => !idx))

  const { run: handleSelectStep } = useThrottleFn(
    image => {
      if (selectedImage) {
        setSelected(null)
        handleSelectStep(image)
      } else {
        setSelected(image)
      }
    },
    { wait: 1000 }
  )

  useEffect(() => {
    if (!selectedImage) return
    const imageIdx = steps.findIndex(({ image }) => selectedImage.asset.id === image.asset.id)
    const isStepOpen = openSteps[imageIdx]
    if (!isStepOpen) {
      const anyOpenedStep = findLastIndex(openSteps, i => i)
      if (anyOpenedStep !== -1) {
        const newImage = steps[anyOpenedStep].image
        const isImageSelected = newImage.asset.id === selectedImage.asset.id
        if (!isImageSelected) handleSelectStep(newImage)
      }
    }
  }, [openSteps, selectedImage, steps, handleSelectStep])

  const handleSwitchOpen = useCallback(
    index => {
      const newStatus = !openSteps[index]
      const newOpenSteps = [...openSteps.slice(0, index), newStatus, ...openSteps.slice(index + 1)]

      if (newStatus) {
        const newImage = steps[index].image
        if (newImage.asset.id !== selectedImage?.asset.id) handleSelectStep(newImage)
      }

      setOpenSteps(newOpenSteps)
    },
    [openSteps, handleSelectStep, steps, selectedImage]
  )

  const renderSteps = () => (
    <div className={styles.steps}>
      {steps.map((step, idx) => (
        <Step key={step._key} index={idx} {...step} open={openSteps[idx]} setOpen={() => handleSwitchOpen(idx)} />
      ))}
    </div>
  )

  const renderImage = () => {
    const side = imgPosition === "left" ? { left: true } : { right: true }
    return (
      <div className={styles.imageContainer}>
        <Fade when={!!selectedImage} {...side}>
          <Image image={selectedImage?.asset} />
        </Fade>
      </div>
    )
  }

  return (
    <div className={styles.faq}>
      <h2 className="text-align-center mb-s">{title}</h2>
      <div className={classNames("text-align-center mb-m", styles.mainText)}>
        <RichText blocks={text} />
      </div>
      <div className={classNames(styles.body, imgPosition === "left" && styles.left)}>
        {renderSteps()}
        {renderImage()}
      </div>
      <Buttons className={styles.buttonContainer} buttons={buttons} />
    </div>
  )
}

FAQ.propTypes = {
  title: PropTypes.string,
  text: PropTypes.arrayOf(PropTypes.any),
  steps: PropTypes.arrayOf(PropTypes.any),
  buttons: PropTypes.arrayOf(PropTypes.any),
  image: PropTypes.objectOf(PropTypes.any),
  imgPosition: PropTypes.oneOf(["left", "right"]),
}

FAQ.defaultProps = {
  title: "",
  text: [],
  steps: [],
  buttons: [],
  image: null,
  imgPosition: "right",
}

export default FAQ
