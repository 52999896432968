import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import PcCard from "./pcCard"
import FormattedLink from "../formattedLink/formattedLink"

import * as styles from "./pricingCourses.module.scss"

const PricingCourses = props => {
  const { cards, label, url } = props

  return (
    <div>
      <div className={styles.cardContainer}>
        {cards.map(card => (
          <PcCard key={card._key} {...card} />
        ))}
      </div>
      {label && (
        <div className="text-align-center">
          <FormattedLink url={url} label={label} className={cx(styles.allCoursesButton, "btn btn-gradient")} />
        </div>
      )}
    </div>
  )
}

PricingCourses.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.any),
  label: PropTypes.string,
  url: PropTypes.string,
}

PricingCourses.defaultProps = {
  cards: [],
  label: "",
  url: "",
}

export default PricingCourses
