import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import FormattedLink from "../formattedLink/formattedLink"

import CircleArrow from "../../../assets/svgs/circle-arrow.svg"

import * as styles from "./findOutMore.module.scss"

const FindOutMore = ({ label, url, styleType, className }) => {

  const Container = props =>
    url ? (
      <FormattedLink url={url} {...props}>
        {props.children}
      </FormattedLink>
    ) : (
      <div {...props}>{props.children}</div>
    )

  return (
    <Container className={cx(styles.link, className, "bold")}>
      {label}
      <CircleArrow className={cx(styles.icon, styles[styleType])} />
    </Container>
  )
}

FindOutMore.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
  styleType: PropTypes.oneOf(["primary", "secondary"]),
  className: PropTypes.string,
}

FindOutMore.defaultProps = {
  label: "Find out more",
  url: "",
  styleType: "primary",
  className: "",
}

export default FindOutMore
