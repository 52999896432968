// extracted by mini-css-extract-plugin
export var sbShowMain = "ProfessionalServices-module--sb-show-main--BhcP5";
export var chart__logoDescr = "ProfessionalServices-module--chart__logoDescr--2Mi7m";
export var chart__logoWrapper = "ProfessionalServices-module--chart__logoWrapper--64JH4";
export var chart__section = "ProfessionalServices-module--chart__section--2EC9L";
export var chart__section__item = "ProfessionalServices-module--chart__section__item--35gUE";
export var chart__wrapper = "ProfessionalServices-module--chart__wrapper--17XDW";
export var wrapper = "ProfessionalServices-module--wrapper--2IdQk";
export var right = "ProfessionalServices-module--right--3ZLKw";
export var left = "ProfessionalServices-module--left--sHyBz";
export var styleRow = "ProfessionalServices-module--styleRow--1QsX0";