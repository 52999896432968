import React from "react"
import PropTypes from "prop-types"
import { VscQuote } from "react-icons/vsc"

import RichText from "../richText/richText"
import Buttons from "../buttons/buttons"

import * as styles from "./testimonial.module.scss"

const Testimonial = props => {
  const { title, author, quoteTitle, richText, links } = props?.tabs ? props.tabs.content : props
  const { name, role } = props?.tabs ? props?.tabs?.testimonialListingPreview : ""
  const nameAndRole = author || (name && role && `${name}, ${role}`) || name || role
  return (
    <div className={styles.testimonial}>
      <h2 className="mb-m text-align-center">{title}</h2>
      <div className={styles.body}>
        <div className="grid-line" />
        <div className="icon">
          <VscQuote color="black" />
        </div>
        <div className="mb-s text-align-center body3 bold uppercase">{quoteTitle}</div>
        <RichText blocks={richText} />
        {nameAndRole && (
          <div className="mt-s text-align-center body5 bold uppercase" style={{ display: "block" }}>
            {nameAndRole}
          </div>
        )}
      </div>
      <Buttons buttons={links} variant="outlined" />
    </div>
  )
}

Testimonial.propTypes = {
  title: PropTypes.string,
  author: PropTypes.string,
  quoteTitle: PropTypes.string,
  richText: PropTypes.arrayOf(PropTypes.any),
  links: PropTypes.arrayOf(PropTypes.any),
  name: PropTypes.string,
  role: PropTypes.string,
}

Testimonial.defaultProps = {
  title: "",
  quoteTitle: "",
  author: "",
  richText: [],
  links: [],
  name: "",
  role: "",
}

export default Testimonial
