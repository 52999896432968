import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { upperFirst } from "lodash"

import EduWorkButton from "../ctaEduWork/eduWorkButton"
import MediumReadingCard from "../moreReading/mediumReadingCard"
import FindOutMore from "../findOutMore/findOutMore"
import FormattedLink from "../formattedLink/formattedLink"
import { useTranslation } from "../../helpers/useTranslation"

import * as styles from "./testimonial.module.scss"

const TestimonialListing = props => {
  const { hideFilterButtons, testimonials } = props
  const { t } = useTranslation()
  const [filteredTestimonials, setFilteredTestimonials] = useState([])
  const [visible, setVisible] = useState(false)

  const filterTestimonials = theme => {
    const filtered = testimonials.filter(
      testimonial => testimonial.tabs.testimonialListingPreview.label.title === theme
    )
    setFilteredTestimonials(filtered)
  }

  const handleFilterButton = theme => {
    filterTestimonials(theme)
    setVisible(true)
  }

  const handleAllClick = () => {
    setFilteredTestimonials(testimonials)
    setVisible(false)
  }

  useEffect(() => {
    setFilteredTestimonials(testimonials)
  }, [testimonials])

  const renderButtons = () => {
    return (
      <>
        <div className={styles.buttonsContainer}>
          <div
            onClick={() => handleFilterButton("edu")}
            onKeyPress={() => handleFilterButton("edu")}
            role="button"
            tabIndex={0}>
            <EduWorkButton
              variant="rounded"
              className={styles.btn}
              description={<span>{t("See what EDU customers say about us")}</span>}
              type="edu"
            />
          </div>
          <div
            onClick={() => handleFilterButton("work")}
            onKeyPress={() => handleFilterButton("work")}
            role="button"
            tabIndex={0}>
            <EduWorkButton
              variant="rounded"
              className={styles.btn}
              description={<span>{t("See what WORK customers say about us")}</span>}
              type="work"
            />
          </div>
        </div>
      </>
    )
  }

  const renderCards = () => {
    return (
      <div className={styles.testimonialListingContainer}>
        {filteredTestimonials.map(card => {
          return <MediumReadingCard key={card._id} {...card} />
        })}
      </div>
    )
  }

  return (
    <div>
      {!hideFilterButtons && renderButtons()}
      {renderCards()}
      {visible && (
        <div
          className="mt-s"
          onClick={() => handleAllClick()}
          onKeyPress={() => handleAllClick()}
          role="button"
          tabIndex={0}>
          <FindOutMore url="" className={styles.buttonLink} label={upperFirst(t("See all"))} />
        </div>
      )}
      {props.link && (
        <FormattedLink className="h6 text-align-center mt-s" {...props.link} variant="outlined" tabIndex={0} />
      )}
    </div>
  )
}

TestimonialListing.propTypes = {
  testimonials: PropTypes.arrayOf(PropTypes.any).isRequired,
  hideFilterButtons: PropTypes.bool,
}

TestimonialListing.defaultProps = {
  hideFilterButtons: false,
}

export default TestimonialListing
