import React from "react"
import PropTypes from "prop-types"

import Form from "../form/form"
import BlockContent from "../richText/richText"

const FormText = ({ form, richText }) => {
  return (
    <div className="grid">
      {richText.length ? (
        <>
          <div className="gridItem large-6">
            <BlockContent blocks={richText || []} />
          </div>
          <div className="gridItem large-6">
            <Form title={form.title} link={form.link} />
          </div>
        </>
      ) : (
        <div className="gridItem small-12">
          <Form title={form.title} link={form.link} />
        </div>
      )}
    </div>
  )
}

FormText.propTypes = {
  form: PropTypes.shape({
    link: PropTypes.string.isRequired,
    title: PropTypes.string,
  }),
  richText: PropTypes.array,
}

FormText.defaultProps = {
  form: {
    link: "",
    title: "",
  },
  richText: [],
}

export default FormText
